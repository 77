
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, connectAuthEmulator } from "firebase/auth";
import { firebaseConfig } from "../firebase-config";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

export const Firebase = initializeApp(firebaseConfig);
export const functions = getFunctions(Firebase, "us-central1");
export const auth = getAuth(Firebase);
export const firestore = getFirestore(Firebase);
export const Providers = { google: new GoogleAuthProvider() };

if (window.location.hostname === 'localhost') {
    // eslint-disable-next-line no-console
    console.log('Using Emulator');
    connectFunctionsEmulator(functions, "localhost", 7002);
    connectAuthEmulator(auth, "http://localhost:7001");
    connectFirestoreEmulator(firestore, "localhost", 7004);
  }